@import url("https://fonts.googleapis.com/css2?family=Space+Mono:wght@400;700&display=swap");

* {
  margin: 0;
  padding: 0;
  color: white;
  font-family: "Space Mono", monospace;
  overflow-x: hidden;
}
/* SCROLLBAR */
html {
  --scrollbarBG: none;
  --thumbBG: #90A4AE;
}
body::-webkit-scrollbar {
  width: 5px;
}
body {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

body {
  background-color: black;
  cursor: none;
}

.webgl {
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
  z-index: -5;
}

.loading {
  width: 100vw;
  height: 100vh;
  z-index: 5;
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: display 1s;
}

.loading-bar {
  background-color: white;
  height: 2px;
  width: 100%;
  transform: scaleX(0);
  transform-origin: top left;
  text-align: center;
  margin-top: 4rem;
}

#robyte-logo {
  position: absolute;
  top: 2rem;
  left: 2rem;
}

#home {
  height: 80vh;
  width: 100vw;
  margin-bottom: 20vh;
  /* text-align: center; */
  font-size: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 4;
}

.planet-section {
  position: relative;
  z-index: -1;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

#rotating-planets-text {
  z-index: 5;
}

#group-planets {
  top: 20vh;
  z-index: 5;
}

.cursor-outer,
.cursor-inner {
  position: fixed;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  z-index: 999999;
  pointer-events: none;
}

.cursor-outer {
  width: 50px;
  height: 50px;
  border: 1px solid white;
  transition: all 0.08s ease-out;
}

.cursor-inner {
  width: 7px;
  height: 7px;
  background: white;
}

.hover-parent ~ .cursor-outer .hover-text:hover {
  width: 90px;
  height: 90px;
  background: white;
  mix-blend-mode: difference;
}

.hover-text:hover ~ .cursor-inner {
  opacity: 0;
}

/* PLANET GROUPS */
#terr-planets {
border-top: 1px solid white;
position: absolute;
top: 40%;
width: 30vw;
text-align: center;
opacity: 0;
z-index: 100;
}

#gas-giants {
  border-bottom: 1px solid white;
  position: absolute;
  right: 19%;
  top: 15%;
  width: 13vw;
  text-align: center;
  opacity: 0;
  z-index: 100;
}

#ice-giants {
  border-top: 1px solid white;
  position: absolute;
  right: 0;
  top: 40%;
  width: 10vw;
  text-align: center;
  opacity: 0;
  z-index: 100;
}
